<template>
    <v-container>
        <v-overlay :value="!isComponentInitialized">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-spacer class="hidden-md-and-down"></v-spacer>
            <v-col class="pt-0 px-8 mb-8 rounded-b-xl background">
                <h1 class="text-center">
                    Giveaway Manager
                </h1>
            </v-col>
            <v-spacer class="hidden-md-and-down"></v-spacer>
        </v-row>
        <v-card color="background">
            <v-card-text>
                <v-row dense>
                    <v-col
                        v-for="([name, properties]) in Object.entries(filters.date)"
                        cols="6" md="3"
                    >
                        <v-menu
                            v-model="properties.popup"
                            :close-on-content-click="false"
                            min-width="auto"
                            offset-y
                            transition="scale-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="(properties.value || []).join(' - ')"
                                    :label="ld.startCase(name)"
                                    clearable
                                    color="secondary"
                                    outlined
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="properties.value = []"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="properties.value"
                                color="secondary"
                                first-day-of-week="1"
                                no-title
                                range
                                show-current
                                @input="properties.popup = properties.value.length < 2"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="10" md="5">
                        <v-autocomplete
                            v-model="filters.autocomplete.tags.selected"
                            :items="filters.autocomplete.tags.options"
                            auto-select-first
                            clearable
                            color="secondary"
                            deletable-chips
                            full-width
                            hide-selected
                            item-color="secondary"
                            label="Tags"
                            multiple
                            outlined
                            small-chips
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="2" md="1">
                        <v-btn
                            class="py-7 w-100"
                            title="Search for contests."
                            :loading="entities.contests.loading"
                            @click="fetchContests"
                            small
                        >
                            <v-icon>
                                mdi-feature-search-outline
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <contest-contract-wizards
                    v-if="services.giveawayContestRewardContract"
                    title="Contract Address (Wizards, Enhancements)"
                    :services="services"
                ></contest-contract-wizards>

                <contest-contract-lands
                    v-if="servicesV2.giveawayContestRewardContract"
                    title="Contract AddressV2 (Lands)"
                    :services="servicesV2"
                ></contest-contract-lands>

            </v-card-text>
        </v-card>
        <v-card class="lighten-1" color="background" min-height="500px">
            <v-overlay :value="isComponentInitialized && entities.contests.loading" absolute>
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
            <v-card-text>
                <v-data-iterator
                    v-if="!entities.contests.loading"
                    :items="entities.contests.DTOs"
                >
                    <template v-slot:default="props">
                        <v-row>
                            <v-col
                                v-for="item in props.items"
                                :key="item.id + '_' + item.name"
                                cols="12"
                                lg="3"
                                md="4"
                                sm="6"
                            >
                                <contest-administration-card
                                    :all-tags="filters.autocomplete.tags.options"
                                    :item="item"
                                ></contest-administration-card>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
            </v-card-text>
        </v-card>
    </v-container>
</template>


<script>
import _ from 'lodash';
import MainButton from "@/components/ui/main-button";
import ContestAdministrationCard from "@/components/contests/contest-administration-card";
import GiveawayContestSettingsDTO from "@/classes/contests/GiveawayContestSettingsDTO";
import CopyButton from "@/components/ui/copy-button";
import {GiveawayContestRewardContract} from "@/services/contracts/contests/giveawayContestRewardContract";
import ContestAdministrationService from "@/services/contestAdministrationService";
import AssetService from "@/services/assetService";
import {GiveawayContestRewardV2Contract} from "@/services/contracts/contests/giveawayContestRewardV2Contract";
import ContestContractWizards from "@/components/contests/contest-contract-wizards";
import ContestContractLands from "@/components/contests/contest-contract-lands";

export default {
    components: {
        ContestContractLands,
        ContestContractWizards, CopyButton, ContestAdministrationCard, MainButton},
    props: [],
    data() {
        return {
            ld: _,
            isComponentInitialized: false,
            filters: {
                date: {
                    startBetween: {
                        popup: false,
                        value: [],
                    },
                    finishBetween: {
                        popup: false,
                        value: [],
                    },
                },
                autocomplete: {
                    tags: {
                        selected: [],
                        options: [],
                    }
                }
            },
            entities: {
                contests: {
                    /** @type { GiveawayContestSettingsDTO[] } */
                    DTOs: [],
                    loading: true,
                    itemsPerPage: 6
                },
            },
            services: {
                contestAddress: GiveawayContestRewardContract.contractAddress,
                /** @type {ContestAdministrationService} */
                contestAdministration: undefined,
                /** @type {AbstractContestRewardContract} */
                giveawayContestRewardContract: undefined,
                /** @type {AssetService} */
                assetService: undefined,
            },
            servicesV2: {
                contestAddress: GiveawayContestRewardV2Contract.contractAddress,
                /** @type {ContestAdministrationService} */
                contestAdministration: undefined,
                /** @type {AbstractContestRewardContract} */
                giveawayContestRewardContract: undefined,
                /** @type {AssetService} */
                assetService: undefined,
            }
        }
    },
    async mounted() {
        this.services.contestAdministration = new ContestAdministrationService();
        this.services.giveawayContestRewardContract = new GiveawayContestRewardContract(this.web3);
        this.services.assetService = new AssetService(this.web3, this.flashMessage)

        this.servicesV2.contestAdministration = new ContestAdministrationService();
        this.servicesV2.giveawayContestRewardContract = new GiveawayContestRewardV2Contract(this.web3);
        this.servicesV2.assetService = new AssetService(this.web3, this.flashMessage)

        this.filters.autocomplete.tags.options = await this.services.contestAdministration.getAllTags();

        await this.fetchContests();

        this.isComponentInitialized = true;
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
    },
    methods: {
        async fetchContests() {
            const parsedStartBetween = this.filters.date.startBetween.value?.sort()
                .map(v => new Date(v))
                .map((d, i) => {
                    d.setDate(d.getDate() + i);
                    return d.toUTCString()
                });
            const parsedFinishBetween = this.filters.date.finishBetween.value?.sort()
                .map(v => new Date(v))
                .map((d, i) => {
                    d.setDate(d.getDate() + i);
                    return d.toUTCString()
                });

            this.entities.contests.loading = true;
            const fetchedContests = await this.services.contestAdministration.getContests(
                parsedStartBetween,
                parsedFinishBetween,
                this.filters.autocomplete.tags.selected
            );
            const emptyContest = new GiveawayContestSettingsDTO(null);

            this.entities.contests.DTOs = [emptyContest, ...fetchedContests.sort((c1, c2) => c2.id - c1.id)];
            this.entities.contests.loading = false;
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
