<template>
    <v-card v-if="contestDTO" class="card-frame">
        <v-form
            v-model="controls.inputsValid"
        >
            <v-card-title class="background">
                <h4
                    :class="{
                        green: contestDTO.publish_status === publishStatuses.PUBLISHED,
                        red: contestDTO.publish_status === publishStatuses.HALTED,
                        yellow: contestDTO.publish_status === publishStatuses.DRAFT,
                        grey: contestDTO.publish_status === publishStatuses.NEW,
                    }"
                    class="w-100 pb-2 text-center"
                >{{ contestDTO.publish_status }}</h4>
                <v-menu
                    :close-on-content-click="false"
                    :disabled="!isInEditMode"
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-img
                            :class="{pointer: isInEditMode}"
                            :src="`/img/profile/contest/background/${contestDTO.background_name}.png`"
                            class="align-end"
                            contain
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!isInEditMode"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-img
                                        :class="{pointer: isInEditMode}"
                                        :src="`/img/profile/contest/icon/${contestDTO.icon_name}.png`"
                                        class="ma-1 rounded-lg background"
                                        v-bind="attrs"
                                        width="75px"
                                        v-on="on"
                                    >
                                    </v-img>
                                </template>
                                <v-container class="background" style="max-width: 600px">
                                    <v-row dense>
                                        <v-col
                                            v-for="(imageName, index) in controls.icons.available"
                                            :key="'icon' + index"
                                            :class="{golden: imageName === contestDTO.icon_name}"
                                            class="rounded-lg d-flex flex-column"
                                            cols="4"
                                            md="3"

                                        >
                                            <v-img
                                                v-ripple
                                                :src="`/img/profile/contest/icon/${imageName}.png`"
                                                class="pointer align-self-center"
                                                contain
                                                max-width="100px"
                                                @click="contestDTO.icon_name = imageName"
                                            >
                                            </v-img>
                                            <div class="background text-center text-truncate rounded-lg">{{
                                                    imageName
                                                }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-menu>
                        </v-img>
                    </template>
                    <v-container class="background" style="max-width: 750px">
                        <v-row dense>
                            <v-col
                                v-for="(imageName, index) in controls.backgrounds.available"
                                :key="'background' + index"
                                :class="{
                                    golden: imageName === contestDTO.background_name
                                }" cols="4"
                                md="3"
                            >
                                <v-img
                                    v-ripple
                                    :src="`/img/profile/contest/background/${imageName}.png`"
                                    class="align-end pointer"
                                    contain
                                    max-width="300px"
                                    @click="contestDTO.background_name = imageName"
                                >
                                    <span class="ma-2 pa-2 rounded-t-xl background">{{ imageName }}</span>
                                </v-img>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-menu>
                <v-row class="px-2" no-gutters>
                    <v-col cols="2">
                        <v-text-field
                            :value="contestDTO.id"
                            label="Id"
                            readonly
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="10">
                        <v-text-field
                            :readonly="!isInEditMode"
                            :value="contestDTO.name"
                            @change="(value) => contestDTO.name = value"
                            label="Contest Name"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12">
                        <v-combobox
                            v-model="contestDTO.tags"
                            :clearable="isInEditMode"
                            :deletable-chips="isInEditMode"
                            :items="controls.tags.options"
                            :readonly="!isInEditMode"
                            :rules="controls.tags.rules"
                            color="golden"
                            hide-selected
                            label="Tags"
                            multiple
                            small-chips
                        ></v-combobox>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <v-menu
                            v-model="controls.timespan.open"
                            :close-on-content-click="false"
                            min-width="auto"
                            offset-y
                            transition="scale-transition"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="controls.timespan.selection.join(' - ')"
                                    :clearable="isInEditMode"
                                    :rules="controls.timespan.rules"
                                    color="secondary"
                                    dense
                                    label="Timespan"
                                    outlined
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="timespanCleared"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="controls.timespan.selection"
                                :readonly="!isInEditMode"
                                color="secondary"
                                first-day-of-week="1"
                                no-title
                                range
                                show-current
                                @input="timespanSelected"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <div
                            v-if="contestDTO.publish_status === publishStatuses.PUBLISHED"
                            class="d-flex flex-column"
                        >
                            <v-list
                                class="lighten-2"
                                color="background"
                                dense
                                rounded
                            >
                                <div class="text-center">
                                    Winner Statuses
                                </div>
                                <v-subheader>
                                    <v-progress-linear
                                        :value="claimedPercent"
                                        class="mr-2"
                                        color="background"
                                        height="20px"
                                        rounded
                                    >
                                        <span class="bold">{{ claimedPercent }}%</span>
                                    </v-progress-linear>
                                </v-subheader>
                                <v-subheader v-if="!contestDTO.winner_statuses">
                                    <v-progress-linear height="10px" indeterminate></v-progress-linear>
                                </v-subheader>
                                <v-list-item
                                    v-for="(winner, i) in contestDTO.winner_statuses"
                                    v-if="contestDTO.winner_statuses"
                                    :key="i"
                                >
                                    <v-list-item-icon>
                                        <v-icon v-if="winner.claimed" color="green">mdi-checkbox-marked-circle</v-icon>
                                        <v-icon v-else color="red">mdi-account-clock</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="winner.address"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-expansion-panels>
                                <v-expansion-panel class="background">
                                    <v-expansion-panel-header>
                                        Add Winners
                                        <template v-slot:actions>
                                            <v-icon>
                                                mdi-plus
                                            </v-icon>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <div class="d-flex flex-column">
                                            <v-textarea
                                                :rules="controls.additionalWinners.rules"
                                                :value="controls.additionalWinners.value.join(', ')"
                                                color="golden"
                                                label="Additional winners"
                                                style="font-size: 13px"
                                                @change="additionalWinnersChanged"
                                                @click:clear="controls.additionalWinners.value = []"
                                            >
                                            </v-textarea>
                                            <v-btn
                                                :disabled="invalidAddressesAdditionalWinners.length > 0 || metamask.addWinners.inProgress"
                                                :loading="metamask.addWinners.inProgress"
                                                class="align-self-end"
                                                @click="addWinners"
                                            >
                                                Add Winners
                                            </v-btn>
                                        </div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                        <v-textarea
                            v-else
                            :clearable="isInEditMode"
                            :readonly="!isInEditMode"
                            :rules="controls.winners.rules"
                            :value="contestDTO.winners.join(', ')"
                            color="golden"
                            label="Winners"
                            style="font-size: 13px"
                            @change="baseWinnersChanged"
                            @click:clear="contestDTO.winners = []"
                        >
                        </v-textarea>
                        <v-alert
                            v-if="invalidAddressesWinnersAll.length > 0"
                            type="error"
                        >
                            <ul>
                                <li
                                    v-for="(adr,i) in invalidAddressesWinnersAll"
                                    :key="'adr_'+i"
                                    class="text-break"
                                >
                                    {{ adr }}
                                </li>

                            </ul>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-radio-group
                            v-model="contestDTO.reward_type"
                            row
                            :readonly="!isInEditMode"
                            dense
                            @change="rewardTypeChanged"
                        >
                            <v-radio
                                v-for="reward in controls.rewards.types"
                                :key="'reward_' + reward"
                                :label="reward"
                                :value="reward"
                                color="golden"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col v-if="contestDTO.reward_type === contestRewardTypes.WIZARD" cols="12">
                        <v-text-field
                            :readonly="!isInEditMode"
                            :value="contestDTO.rewards[0]?.amount || 0"
                            :rules="[(v) => (v > 0) || 'Rly? Negative win?']"
                            color="golden"
                            hide-spin-buttons
                            label="Amount"
                            type="number"
                            @change="wizardsAmountChange"
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="contestDTO.reward_type === contestRewardTypes.LAND" cols="12">
                        <v-text-field
                            :readonly="!isInEditMode"
                            :value="contestDTO.rewards.map(v => v.id).join(', ')"
                            color="golden"
                            label="Ids"
                            @change="landsSelected"
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="contestDTO.reward_type === contestRewardTypes.CARD" cols="12">
                        <v-text-field
                            :readonly="!isInEditMode"
                            :value="contestDTO.rewards.map(v => v.id).join(', ')"
                            color="golden"
                            label="Ids"
                            @change="cardsSelected"
                        ></v-text-field>
                        <v-row dense>
                            <v-col
                                v-for="(reward, index) in contestDTO.rewards"
                                :key="`card+${index}`"
                                cols="3"
                            >
                                <v-img
                                    :src="`https://cdn.wizarre.io/img/c/145/${reward.id}.png`"
                                >
                                    <span class="background pa-1 rounded-br-lg">Id: {{ reward.id }}</span>
                                </v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="contestDTO.reward_type === contestRewardTypes.ENHANCEMENT" cols="12">
                        <v-text-field
                            :readonly="!isInEditMode"
                            :value="contestDTO.rewards.map(v => v.id).join(', ')"
                            color="golden"
                            label="Ids"
                            @change="enhancementsSelected"
                        ></v-text-field>
                        <v-row dense>
                            <v-col
                                v-for="(reward, index) in contestDTO.rewards"
                                :key="`card+${index}`"
                                cols="3"
                            >
                                <v-img
                                    :src="`https://cdn.wizarre.io/img/e/300/${reward.id}.png`"
                                >
                                    <span class="background pa-1 rounded-br-lg">Id: {{ reward.id }}</span>
                                </v-img>
                                <v-text-field
                                    v-model="reward.amount"
                                    :readonly="!isInEditMode"
                                    :rules="[(v) => (v > 0) || 'Rly? Negative win?']"
                                    color="golden"
                                    dense
                                    reverse
                                    type="Number"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="background justify-end">
                <v-btn
                    v-if="contestDTO.publish_status === publishStatuses.NEW"
                    :disabled="!controls.inputsValid || api.save.inProgress"
                    :loading="api.save.inProgress"
                    class="darken-1"
                    color="golden"
                    @click="saveContest"
                >
                    Save
                </v-btn>
                <v-btn
                    v-if="contestDTO.publish_status === publishStatuses.DRAFT"
                    :disabled="metamask.publish.inProgress"
                    :loading="metamask.publish.inProgress"
                    class="darken-1"
                    color="golden"
                    @click="publishContest"
                >
                    Publish
                </v-btn>
                <div
                    v-if="contestDTO.publish_status === publishStatuses.PUBLISHED"
                    class="d-flex align-center"
                >
                    <v-switch
                        v-model="metamask.halt.isUnlocked"
                        class="ml-4"
                        color="red"
                        dense
                    ></v-switch>
                    <v-btn
                        :disabled="!metamask.halt.isUnlocked || metamask.halt.inProgress"
                        :loading="metamask.halt.inProgress"
                        class="darken-2"
                        color="red"
                        @click="haltContest"
                    >
                        Halt!
                    </v-btn>
                </div>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>

import GiveawayContestSettingsDTO from "@/classes/contests/GiveawayContestSettingsDTO";
import {ContestPublishStatuses, ContestRewardTypes} from "@/classes/contests/ContestHelperClasses";
import WalletIntegration from "@/wallet-integration";
import ContestAdministrationService from "@/services/contestAdministrationService";
import {UserWalletError} from "@/errors/WalletErrors";
import {GiveawayContestRewardContract} from "@/services/contracts/contests/giveawayContestRewardContract";
import SignService from "@/services/contracts/signService";
import {GiveawayContestRewardV2Contract} from "@/services/contracts/contests/giveawayContestRewardV2Contract";

export default {
    props: {
        item: {
            type: GiveawayContestSettingsDTO,
            default: new GiveawayContestSettingsDTO({}),
        },
        allTags: {
            type: Array(),
            default: [],
        },
    },
    data() {
        return {
            /** @type { GiveawayContestSettingsDTO } */
            contestDTO: undefined,
            contestRewardTypes: ContestRewardTypes,
            publishStatuses: ContestPublishStatuses,
            controls: {
                inputsValid: true,
                backgrounds: {
                    available: [],
                },
                icons: {
                    available: [],
                },
                timespan: {
                    open: false,
                    selection: [],
                    rules: [
                        _ => (this.controls.timespan.selection || []).length === 2 ||
                            'Both start and end dates should be selected',
                    ]
                },
                tags: {
                    options: [],
                    rules: [
                        v => (v || []).length >= 3 ||
                            'Select 3 of more tags',
                    ]
                },
                winners: {
                    rules: [
                        _ => (this.invalidAddressesWinnersBase || []).length === 0 ||
                            'Found invalid wallet address',
                    ]
                },
                additionalWinners: {
                    value: [],
                    rules: [
                        _ => (this.invalidAddressesAdditionalWinners || []).length === 0 ||
                            'Found invalid wallet address',
                    ]
                },
                rewards: {
                    types: [ContestRewardTypes.WIZARD, ContestRewardTypes.LAND, ContestRewardTypes.ENHANCEMENT, ContestRewardTypes.CARD],
                },
            },
            metamask: {
                publish: {
                    inProgress: false,
                },
                halt: {
                    isUnlocked: false,
                    inProgress: false
                },
                addWinners: {
                    inProgress: false
                }
            },
            api: {
                save: {
                    inProgress: false,
                }
            },
            services: {
                /** @type {ContestAdministrationService} */
                contestAdministration: undefined,
                /** @type {GiveawayContestRewardContract} */
                giveawayContestRewardContract: undefined,
            }
        }
    },
    mounted() {
        this.services.contestAdministration = new ContestAdministrationService();
        this.services.giveawayContestRewardContract = new GiveawayContestRewardContract(this.web3);
        this.services.giveawayContestRewardV2Contract = new GiveawayContestRewardV2Contract(this.web3);

        this.contestDTO = this.item;
        this.contestDTO.start_timestamp = new Date(this.contestDTO.start_timestamp).getTime();
        this.contestDTO.end_timestamp = new Date(this.contestDTO.end_timestamp).getTime();

        this.controls.timespan.selection = [
            this.parseTimestampForSelector(this.contestDTO.start_timestamp),
            this.parseTimestampForSelector(this.contestDTO.end_timestamp),
        ]

        this.controls.tags.options = this.allTags;

        this.controls.backgrounds.available = require.context(
            '/public/img/profile/contest/background/',
            true,
            /^.*\.png$/
        )
            .keys()
            .map(s => s.replace('.png', ''))
            .map(s => s.replace('./', ''));

        this.controls.icons.available = require.context(
            '/public/img/profile/contest/icon/',
            true,
            /^.*\.png$/
        )
            .keys()
            .map(s => s.replace('.png', ''))
            .map(s => s.replace('./', ''));

    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        walletAddress() {
            return this.web3.address?.toLowerCase();
        },
        isInEditMode() {
            return this.contestDTO.publish_status === ContestPublishStatuses.NEW;
        },
        claimedPercent() {
            if (this.contestDTO.winner_statuses) {
                const winners = this.contestDTO.winner_statuses.length;
                const claimed = this.contestDTO.winner_statuses.filter(v => v.claimed).length
                return Math.round(claimed * 100 / winners);
            }
            return 0
        },
        invalidAddressesWinnersBase() {
            return this.contestDTO
                .winners
                .filter(adr => !WalletIntegration.isAddress(adr));
        },
        invalidAddressesAdditionalWinners() {
            return this.controls
                .additionalWinners
                .value
                .filter(adr => !WalletIntegration.isAddress(adr));
        },
        invalidAddressesWinnersAll() {
            return [...this.invalidAddressesAdditionalWinners, ...this.invalidAddressesWinnersBase]
        },
    },
    methods: {
        parseTimestampForSelector(timestamp) {
            return new Date(timestamp).toISOString().slice(0, 10);
        },
        timespanSelected() {
            this.controls.timespan.open = this.controls.timespan.selection.length < 2;
            this.contestDTO.start_timestamp = new Date(this.controls.timespan.selection.sort()[0]).getTime();
            this.contestDTO.end_timestamp = new Date(this.controls.timespan.selection.sort()[1]).getTime();
        },
        timespanCleared() {
            this.controls.timespan.selection = [];
            this.contestDTO.start_timestamp = 0
            this.contestDTO.end_timestamp = 0
        },
        baseWinnersChanged(value) {
            this.contestDTO.winners = value?.split(/[\s,]+/).filter(v => v.length > 0) || [];
        },
        additionalWinnersChanged(value) {
            this.controls.additionalWinners.value = value?.split(/[\s,]+/).filter(v => v.length > 0) || [];
        },
        rewardTypeChanged() {
            this.contestDTO.rewards = [];
        },
        wizardsAmountChange(value) {
            const number = Math.round(value);
            this.contestDTO.rewards = [{amount: number}];
        },
        landsSelected(value) {
            this.contestDTO.rewards = value?.split(/[\s,]+/)
                .map(v => Number(v))
                .filter(v => !!v)
                .map(v => Math.abs(v))
                .map(v => Math.round(v))
                .map(v => ({id: v}));
        },
        cardsSelected(value) {
            this.contestDTO.rewards = value?.split(/[\s,]+/)
                .map(v => Number(v))
                .filter(v => !!v)
                .map(v => Math.abs(v))
                .map(v => Math.round(v))
                .map(v => ({id: v}));
        },
        enhancementsSelected(value) {
            this.contestDTO.rewards = value?.split(/[\s,]+/)
                .map(v => Number(v))
                .filter(v => v !== null)
                .map(v => Math.abs(v))
                .map(v => Math.round(v))
                .map(v => ({id: v, amount: 1}));
        },
        async saveContest() {
            this.api.save.inProgress = true;
            const nonce = await this.services.contestAdministration.fetchGiveawaySaveNonce(this.walletAddress);
            const signService = new SignService(this.web3);
            signService.signText(nonce)
                .then(async (signedOnce) => {
                    this.contestDTO = await this.services.contestAdministration
                        .saveGiveawayContest(
                            this.contestDTO,
                            signedOnce,
                            this.walletAddress
                        );
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        this.flashMessage.show({
                            status: 'error',
                            message: 'Sign error'
                        });
                    }
                })
                .finally(() => {
                    this.api.save.inProgress = false;
                })
        },
        async publishContest() {
            this.metamask.publish.inProgress = true;
            try {
                switch (this.contestDTO.reward_type) {
                    case ContestRewardTypes.WIZARD:
                        await this.services.giveawayContestRewardContract.setNewWizardContest(
                            this.contestDTO.id,
                            this.contestDTO.end_timestamp,
                            this.contestDTO.rewards[0].amount,
                            this.contestDTO.winners,
                        );
                        break;
                    // Lands are handled only by V2
                    case ContestRewardTypes.LAND:
                        await this.services.giveawayContestRewardV2Contract.setNewLandContest(
                            this.contestDTO.id,
                            this.contestDTO.end_timestamp,
                            this.contestDTO.rewards.map(r => r.id),
                            this.contestDTO.winners,
                        );
                        break;
                    case ContestRewardTypes.CARD:
                        await this.services.giveawayContestRewardContract.setNewCardContest(
                            this.contestDTO.id,
                            this.contestDTO.end_timestamp,
                            this.contestDTO.rewards.map(r => r.id),
                            this.contestDTO.winners,
                        );
                        break;
                    case ContestRewardTypes.ENHANCEMENT:
                        await this.services.giveawayContestRewardContract.setNewEnhancementContest(
                            this.contestDTO.id,
                            this.contestDTO.end_timestamp,
                            this.contestDTO.rewards.map(r => r.id),
                            this.contestDTO.rewards.map(r => r.amount),
                            this.contestDTO.winners,
                        );
                        break;
                }
                this.flashMessage.show({
                    status: 'success',
                    message: 'Giveaway published on blockchain'
                });

                await this.services.contestAdministration.waitForGiveawayStatus(this.contestDTO.id, ContestPublishStatuses.PUBLISHED);

                this.flashMessage.show({
                    status: 'success',
                    message: 'Giveaway publish acknowledged by backend'
                });

                this.updateContestDTO();

            } catch (error) {
                if (error instanceof UserWalletError) {
                    console.log(error);
                    // do nothing
                } else {
                    this.popupOpen = false;
                    console.error(error);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Publication failed'
                    });
                }
            } finally {
                this.metamask.publish.inProgress = false;
            }
        },
        addWinners() {
            this.metamask.addWinners.inProgress = true;
            this.services.giveawayContestRewardContract.addWinners(
                this.contestDTO.id,
                this.controls.additionalWinners.value,
            ).then(async (blockchainResponse) => {
                this.flashMessage.show({
                    status: 'success',
                    message: 'Winners added on blockchain'
                });

                await this.services.contestAdministration
                    .waitForGiveawayWinnersUpdate(
                        this.contestDTO.id,
                        [
                            ...this.contestDTO.winners,
                            ...this.controls.additionalWinners.value
                        ]);

                this.flashMessage.show({
                    status: 'success',
                    message: 'Change acknowledged by backend'
                });

                this.updateContestDTO();
            }).catch((error) => {
                if (error instanceof UserWalletError) {
                    console.log(error);
                    // do nothing
                } else {
                    this.popupOpen = false;
                    console.error(error);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Change failed'
                    });
                }
            })
                .finally(() => {
                    this.metamask.addWinners.inProgress = false;
                });
        },
        haltContest() {
            this.metamask.halt.inProgress = true;
            this.services.giveawayContestRewardContract.removeContest(
                this.contestDTO.id,
            ).then(async (blockchainResponse) => {
                this.flashMessage.show({
                    status: 'success',
                    message: 'HALTED on blockchain'
                });

                await this.services.contestAdministration.waitForGiveawayStatus(this.contestDTO.id, ContestPublishStatuses.HALTED);

                this.flashMessage.show({
                    status: 'success',
                    message: 'HALT acknowledged by backend'
                });

                this.updateContestDTO();
            }).catch((error) => {
                if (error instanceof UserWalletError) {
                    // do nothing
                } else {
                    this.popupOpen = false;
                    console.error(error);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Halting failed'
                    });
                }
            })
                .finally(() => {
                    this.metamask.halt.inProgress = false;
                });
        },
        updateContestDTO() {
            this.controls.additionalWinners.value = [];
            this.services.contestAdministration.getGiveawayContest(this.contestDTO.id)
                .then(value => this.contestDTO = value);
        },
    },
}
</script>

<style lang="scss" scoped>
.card-frame {
    border: 5px solid var(--v-background-base);
}
</style>
