<template>
    <v-row dense>
        <v-col cols="12" lg="5" md="5" sm="12">
            <v-text-field
                :value="services.contestAddress"
                color="golden"
                :label="title"
                outlined
                readonly>
                <template v-slot:append>
                    <v-chip class="ml-2"
                            color="#435561"
                            small
                            label target="_blank"
                            :href="bscLink">
                        <img src="/img/icons/crypto/bscscan.ico" class="icon"/>
                    </v-chip>
                    <copy-button :text="services.contestAddress"></copy-button>
                </template>
            </v-text-field>
        </v-col>
        <v-col cols="5" lg="3" md="3" sm="5">
            <v-text-field
                :loading="entities.wizards.remaining.loading"
                :value="entities.wizards.remaining.amount"
                color="golden"
                label="Remaining Wizards to claim"
                outlined
                readonly>
                <template v-slot:append>
                    <v-btn
                        :loading="entities.wizards.remaining.loading"
                        color="#435561"
                        small
                        title="Refresh amount."
                        @click="getRemainingWizardsAmount"
                    >
                        <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                </template>
            </v-text-field>
        </v-col>
        <v-col cols="5" lg="3" md="3" sm="5">
            <v-text-field
                :loading="entities.wizards.onWallet.loading"
                :value="entities.wizards.onWallet.list.length"
                color="golden"
                label="Wizards On Contract"
                outlined
                readonly>
                <template v-slot:append>
                    <v-btn
                        :loading="entities.wizards.onWallet.loading"
                        color="#435561"
                        small
                        title="Refresh amount."
                        @click="getWalletWizards"
                    >
                        <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                </template>
            </v-text-field>
        </v-col>
        <v-col cols="2" md="1">
            <v-btn
                :loading="entities.wizards.sync.inProgress"
                class="py-7 w-100"
                small
                title="Sets Wallet Wizards as possible rewards."
                @click="setGiveawayContractWizardIds"
            >
                <v-icon>
                    mdi-cloud-upload
                </v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>


<script>
import _ from 'lodash';
import MainButton from "@/components/ui/main-button";
import ContestAdministrationCard from "@/components/contests/contest-administration-card";
import CopyButton from "@/components/ui/copy-button";

export default {
    components: {CopyButton, ContestAdministrationCard, MainButton},
    props: {
        title: String,
        services: Object,
    },
    data() {
        return {
            entities: {
                wizards: {
                    remaining: {
                        loading: true,
                        amount: 0,
                    },
                    onWallet: {
                        loading: true,
                        list: [],
                    },
                    sync: {
                        inProgress: false,
                    }
                }
            },
        }
    },
    async mounted() {
        await this.getRemainingWizardsAmount();
        await this.getWalletWizards();
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        bscLink() {
            return process.env.VUE_APP_BLOCKCHAIN_BLOCK_EXPLORER_URL + 'address/' + this.services.contestAddress;
        }
    },
    methods: {
        getRemainingWizardsAmount() {
            this.entities.wizards.remaining.loading = true;
            this.services.giveawayContestRewardContract.getRemainingWizardsAmount()
                .then(amount => {
                    this.entities.wizards.remaining.amount = amount;
                })
                .catch(e => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'getRemainingLandsAmount failed'
                    });
                })
                .finally(_ => {
                    this.entities.wizards.remaining.loading = false;
                })
        },
        getWalletWizards() {
            this.entities.wizards.onWallet.loading = true;
            this.services.assetService.fetchWizardsOf(this.services.contestAddress)
                .then(wizards => {
                    this.entities.wizards.onWallet.list = wizards.map(w => w.id);
                })
                .finally(_ => {
                    this.entities.wizards.onWallet.loading = false;
                });
        },
        setGiveawayContractWizardIds() {
            this.entities.wizards.sync.inProgress = true;

            const shuffledIds = _.shuffle(this.entities.wizards.onWallet.list)

            this.services.giveawayContestRewardContract
                .setWizardIds(shuffledIds)
                .then(_ => {

                    this.flashMessage.show({
                        status: 'success',
                        message: `Wizard ids updated successfully`
                    })
                })
                .catch(e => {
                    console.error(e)
                    this.flashMessage.show({
                        status: 'error',
                        message: `Error during setting wizard id's`
                    })
                })
                .finally(_ => {
                    this.entities.wizards.sync.inProgress = false;
                })

        },
    }
};
</script>

<style lang="scss" scoped>
.icon {
    width: 16px;
    height: 16px;
}
</style>
