var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.contestDTO
    ? _c(
        "v-card",
        { staticClass: "card-frame" },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.controls.inputsValid,
                callback: function ($$v) {
                  _vm.$set(_vm.controls, "inputsValid", $$v)
                },
                expression: "controls.inputsValid",
              },
            },
            [
              _c(
                "v-card-title",
                { staticClass: "background" },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "w-100 pb-2 text-center",
                      class: {
                        green:
                          _vm.contestDTO.publish_status ===
                          _vm.publishStatuses.PUBLISHED,
                        red:
                          _vm.contestDTO.publish_status ===
                          _vm.publishStatuses.HALTED,
                        yellow:
                          _vm.contestDTO.publish_status ===
                          _vm.publishStatuses.DRAFT,
                        grey:
                          _vm.contestDTO.publish_status ===
                          _vm.publishStatuses.NEW,
                      },
                    },
                    [_vm._v(_vm._s(_vm.contestDTO.publish_status))]
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        disabled: !_vm.isInEditMode,
                        "offset-y": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-img",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "align-end",
                                        class: { pointer: _vm.isInEditMode },
                                        attrs: {
                                          src: `/img/profile/contest/background/${_vm.contestDTO.background_name}.png`,
                                          contain: "",
                                        },
                                      },
                                      "v-img",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          "close-on-content-click": false,
                                          disabled: !_vm.isInEditMode,
                                          "offset-y": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-img",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "ma-1 rounded-lg background",
                                                          class: {
                                                            pointer:
                                                              _vm.isInEditMode,
                                                          },
                                                          attrs: {
                                                            src: `/img/profile/contest/icon/${_vm.contestDTO.icon_name}.png`,
                                                            width: "75px",
                                                          },
                                                        },
                                                        "v-img",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "background",
                                            staticStyle: {
                                              "max-width": "600px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              { attrs: { dense: "" } },
                                              _vm._l(
                                                _vm.controls.icons.available,
                                                function (imageName, index) {
                                                  return _c(
                                                    "v-col",
                                                    {
                                                      key: "icon" + index,
                                                      staticClass:
                                                        "rounded-lg d-flex flex-column",
                                                      class: {
                                                        golden:
                                                          imageName ===
                                                          _vm.contestDTO
                                                            .icon_name,
                                                      },
                                                      attrs: {
                                                        cols: "4",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        directives: [
                                                          {
                                                            name: "ripple",
                                                            rawName: "v-ripple",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "pointer align-self-center",
                                                        attrs: {
                                                          src: `/img/profile/contest/icon/${imageName}.png`,
                                                          contain: "",
                                                          "max-width": "100px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.contestDTO.icon_name =
                                                              imageName
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "background text-center text-truncate rounded-lg",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(imageName) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        577522587
                      ),
                    },
                    [
                      _c(
                        "v-container",
                        {
                          staticClass: "background",
                          staticStyle: { "max-width": "750px" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            _vm._l(
                              _vm.controls.backgrounds.available,
                              function (imageName, index) {
                                return _c(
                                  "v-col",
                                  {
                                    key: "background" + index,
                                    class: {
                                      golden:
                                        imageName ===
                                        _vm.contestDTO.background_name,
                                    },
                                    attrs: { cols: "4", md: "3" },
                                  },
                                  [
                                    _c(
                                      "v-img",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple",
                                          },
                                        ],
                                        staticClass: "align-end pointer",
                                        attrs: {
                                          src: `/img/profile/contest/background/${imageName}.png`,
                                          contain: "",
                                          "max-width": "300px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.contestDTO.background_name =
                                              imageName
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "ma-2 pa-2 rounded-t-xl background",
                                          },
                                          [_vm._v(_vm._s(imageName))]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "px-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.contestDTO.id,
                              label: "Id",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              readonly: !_vm.isInEditMode,
                              value: _vm.contestDTO.name,
                              label: "Contest Name",
                            },
                            on: {
                              change: (value) => (_vm.contestDTO.name = value),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              clearable: _vm.isInEditMode,
                              "deletable-chips": _vm.isInEditMode,
                              items: _vm.controls.tags.options,
                              readonly: !_vm.isInEditMode,
                              rules: _vm.controls.tags.rules,
                              color: "golden",
                              "hide-selected": "",
                              label: "Tags",
                              multiple: "",
                              "small-chips": "",
                            },
                            model: {
                              value: _vm.contestDTO.tags,
                              callback: function ($$v) {
                                _vm.$set(_vm.contestDTO, "tags", $$v)
                              },
                              expression: "contestDTO.tags",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "min-width": "auto",
                                "offset-y": "",
                                transition: "scale-transition",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  clearable: _vm.isInEditMode,
                                                  rules:
                                                    _vm.controls.timespan.rules,
                                                  color: "secondary",
                                                  dense: "",
                                                  label: "Timespan",
                                                  outlined: "",
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                },
                                                on: {
                                                  "click:clear":
                                                    _vm.timespanCleared,
                                                },
                                                model: {
                                                  value:
                                                    _vm.controls.timespan.selection.join(
                                                      " - "
                                                    ),
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.controls.timespan
                                                        .selection,
                                                      "join(' - ')",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "controls.timespan.selection.join(' - ')",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                634833505
                              ),
                              model: {
                                value: _vm.controls.timespan.open,
                                callback: function ($$v) {
                                  _vm.$set(_vm.controls.timespan, "open", $$v)
                                },
                                expression: "controls.timespan.open",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  readonly: !_vm.isInEditMode,
                                  color: "secondary",
                                  "first-day-of-week": "1",
                                  "no-title": "",
                                  range: "",
                                  "show-current": "",
                                },
                                on: { input: _vm.timespanSelected },
                                model: {
                                  value: _vm.controls.timespan.selection,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.controls.timespan,
                                      "selection",
                                      $$v
                                    )
                                  },
                                  expression: "controls.timespan.selection",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _vm.contestDTO.publish_status ===
                          _vm.publishStatuses.PUBLISHED
                            ? _c(
                                "div",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "lighten-2",
                                      attrs: {
                                        color: "background",
                                        dense: "",
                                        rounded: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [_vm._v(" Winner Statuses ")]
                                      ),
                                      _c(
                                        "v-subheader",
                                        [
                                          _c(
                                            "v-progress-linear",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                value: _vm.claimedPercent,
                                                color: "background",
                                                height: "20px",
                                                rounded: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "bold" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.claimedPercent) +
                                                      "%"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      !_vm.contestDTO.winner_statuses
                                        ? _c(
                                            "v-subheader",
                                            [
                                              _c("v-progress-linear", {
                                                attrs: {
                                                  height: "10px",
                                                  indeterminate: "",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.contestDTO.winner_statuses,
                                        function (winner, i) {
                                          return _vm.contestDTO.winner_statuses
                                            ? _c(
                                                "v-list-item",
                                                { key: i },
                                                [
                                                  _c(
                                                    "v-list-item-icon",
                                                    [
                                                      winner.claimed
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "green",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-checkbox-marked-circle"
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-account-clock"
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", {
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            winner.address
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "v-expansion-panels",
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        { staticClass: "background" },
                                        [
                                          _c(
                                            "v-expansion-panel-header",
                                            {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "actions",
                                                    fn: function () {
                                                      return [
                                                        _c("v-icon", [
                                                          _vm._v(" mdi-plus "),
                                                        ]),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                170992288
                                              ),
                                            },
                                            [_vm._v(" Add Winners ")]
                                          ),
                                          _c("v-expansion-panel-content", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-column",
                                              },
                                              [
                                                _c("v-textarea", {
                                                  staticStyle: {
                                                    "font-size": "13px",
                                                  },
                                                  attrs: {
                                                    rules:
                                                      _vm.controls
                                                        .additionalWinners
                                                        .rules,
                                                    value:
                                                      _vm.controls.additionalWinners.value.join(
                                                        ", "
                                                      ),
                                                    color: "golden",
                                                    label: "Additional winners",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.additionalWinnersChanged,
                                                    "click:clear": function (
                                                      $event
                                                    ) {
                                                      _vm.controls.additionalWinners.value =
                                                        []
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "align-self-end",
                                                    attrs: {
                                                      disabled:
                                                        _vm
                                                          .invalidAddressesAdditionalWinners
                                                          .length > 0 ||
                                                        _vm.metamask.addWinners
                                                          .inProgress,
                                                      loading:
                                                        _vm.metamask.addWinners
                                                          .inProgress,
                                                    },
                                                    on: {
                                                      click: _vm.addWinners,
                                                    },
                                                  },
                                                  [_vm._v(" Add Winners ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c("v-textarea", {
                                staticStyle: { "font-size": "13px" },
                                attrs: {
                                  clearable: _vm.isInEditMode,
                                  readonly: !_vm.isInEditMode,
                                  rules: _vm.controls.winners.rules,
                                  value: _vm.contestDTO.winners.join(", "),
                                  color: "golden",
                                  label: "Winners",
                                },
                                on: {
                                  change: _vm.baseWinnersChanged,
                                  "click:clear": function ($event) {
                                    _vm.contestDTO.winners = []
                                  },
                                },
                              }),
                          _vm.invalidAddressesWinnersAll.length > 0
                            ? _c("v-alert", { attrs: { type: "error" } }, [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.invalidAddressesWinnersAll,
                                    function (adr, i) {
                                      return _c(
                                        "li",
                                        {
                                          key: "adr_" + i,
                                          staticClass: "text-break",
                                        },
                                        [_vm._v(" " + _vm._s(adr) + " ")]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                row: "",
                                readonly: !_vm.isInEditMode,
                                dense: "",
                              },
                              on: { change: _vm.rewardTypeChanged },
                              model: {
                                value: _vm.contestDTO.reward_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.contestDTO, "reward_type", $$v)
                                },
                                expression: "contestDTO.reward_type",
                              },
                            },
                            _vm._l(
                              _vm.controls.rewards.types,
                              function (reward) {
                                return _c("v-radio", {
                                  key: "reward_" + reward,
                                  attrs: {
                                    label: reward,
                                    value: reward,
                                    color: "golden",
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.contestDTO.reward_type ===
                      _vm.contestRewardTypes.WIZARD
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: !_vm.isInEditMode,
                                  value: _vm.contestDTO.rewards[0]?.amount || 0,
                                  rules: [(v) => v > 0 || "Rly? Negative win?"],
                                  color: "golden",
                                  "hide-spin-buttons": "",
                                  label: "Amount",
                                  type: "number",
                                },
                                on: { change: _vm.wizardsAmountChange },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.contestDTO.reward_type === _vm.contestRewardTypes.LAND
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: !_vm.isInEditMode,
                                  value: _vm.contestDTO.rewards
                                    .map((v) => v.id)
                                    .join(", "),
                                  color: "golden",
                                  label: "Ids",
                                },
                                on: { change: _vm.landsSelected },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.contestDTO.reward_type === _vm.contestRewardTypes.CARD
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: !_vm.isInEditMode,
                                  value: _vm.contestDTO.rewards
                                    .map((v) => v.id)
                                    .join(", "),
                                  color: "golden",
                                  label: "Ids",
                                },
                                on: { change: _vm.cardsSelected },
                              }),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                _vm._l(
                                  _vm.contestDTO.rewards,
                                  function (reward, index) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: `card+${index}`,
                                        attrs: { cols: "3" },
                                      },
                                      [
                                        _c(
                                          "v-img",
                                          {
                                            attrs: {
                                              src: `https://cdn.wizarre.io/img/c/145/${reward.id}.png`,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "background pa-1 rounded-br-lg",
                                              },
                                              [
                                                _vm._v(
                                                  "Id: " + _vm._s(reward.id)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.contestDTO.reward_type ===
                      _vm.contestRewardTypes.ENHANCEMENT
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: !_vm.isInEditMode,
                                  value: _vm.contestDTO.rewards
                                    .map((v) => v.id)
                                    .join(", "),
                                  color: "golden",
                                  label: "Ids",
                                },
                                on: { change: _vm.enhancementsSelected },
                              }),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                _vm._l(
                                  _vm.contestDTO.rewards,
                                  function (reward, index) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: `card+${index}`,
                                        attrs: { cols: "3" },
                                      },
                                      [
                                        _c(
                                          "v-img",
                                          {
                                            attrs: {
                                              src: `https://cdn.wizarre.io/img/e/300/${reward.id}.png`,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "background pa-1 rounded-br-lg",
                                              },
                                              [
                                                _vm._v(
                                                  "Id: " + _vm._s(reward.id)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("v-text-field", {
                                          attrs: {
                                            readonly: !_vm.isInEditMode,
                                            rules: [
                                              (v) =>
                                                v > 0 || "Rly? Negative win?",
                                            ],
                                            color: "golden",
                                            dense: "",
                                            reverse: "",
                                            type: "Number",
                                          },
                                          model: {
                                            value: reward.amount,
                                            callback: function ($$v) {
                                              _vm.$set(reward, "amount", $$v)
                                            },
                                            expression: "reward.amount",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "background justify-end" },
                [
                  _vm.contestDTO.publish_status === _vm.publishStatuses.NEW
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "darken-1",
                          attrs: {
                            disabled:
                              !_vm.controls.inputsValid ||
                              _vm.api.save.inProgress,
                            loading: _vm.api.save.inProgress,
                            color: "golden",
                          },
                          on: { click: _vm.saveContest },
                        },
                        [_vm._v(" Save ")]
                      )
                    : _vm._e(),
                  _vm.contestDTO.publish_status === _vm.publishStatuses.DRAFT
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "darken-1",
                          attrs: {
                            disabled: _vm.metamask.publish.inProgress,
                            loading: _vm.metamask.publish.inProgress,
                            color: "golden",
                          },
                          on: { click: _vm.publishContest },
                        },
                        [_vm._v(" Publish ")]
                      )
                    : _vm._e(),
                  _vm.contestDTO.publish_status ===
                  _vm.publishStatuses.PUBLISHED
                    ? _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("v-switch", {
                            staticClass: "ml-4",
                            attrs: { color: "red", dense: "" },
                            model: {
                              value: _vm.metamask.halt.isUnlocked,
                              callback: function ($$v) {
                                _vm.$set(_vm.metamask.halt, "isUnlocked", $$v)
                              },
                              expression: "metamask.halt.isUnlocked",
                            },
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "darken-2",
                              attrs: {
                                disabled:
                                  !_vm.metamask.halt.isUnlocked ||
                                  _vm.metamask.halt.inProgress,
                                loading: _vm.metamask.halt.inProgress,
                                color: "red",
                              },
                              on: { click: _vm.haltContest },
                            },
                            [_vm._v(" Halt! ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }