import GiveawayContestSettingsDTO from "@/classes/contests/GiveawayContestSettingsDTO";
import {apiServiceVue} from "@/services/apiService.vue";

export default class ContestAdministrationService {

    #contestsURL = process.env.VUE_APP_CONTESTS_URL;

    constructor() {
    }

    /**
     *
     * @returns {Promise<string[]>}
     */
    async getAllTags() {
        const response = await apiServiceVue
            .get(process.env.VUE_APP_CONTESTS_URL + 'tags/');
        return response.data.tags;
    }

    /**
     *
     * @param {string[]} startBetween
     * @param {string[]} finishBetween
     * @param {string[]} tags
     * @returns {Promise<GiveawayContestSettingsDTO[]>}
     */
    async getContests(startBetween, finishBetween, tags) {
        const response = await apiServiceVue
            .get(
                process.env.VUE_APP_CONTESTS_URL + 'giveaway/',
                {
                    tags: tags,
                    startBetween: startBetween,
                    finishBetween: finishBetween
                }
            );
        return response.data.giveaways;
    }

    /**
     *
     * @param walletAddress
     * @returns {Promise<String>}
     */
    async fetchGiveawaySaveNonce(walletAddress) {
        const response = await apiServiceVue.get(`${this.#contestsURL}giveaway/new/nonce/${walletAddress}`);
        return response.data.nonce;
    }

    /**
     *
     * @param {GiveawayContestSettingsDTO} contestDTO
     * @param {String} signedOnce
     * @param {String} walletAddress
     * @returns {Promise<GiveawayContestSettingsDTO>}
     */
    async saveGiveawayContest(contestDTO, signedOnce, walletAddress) {
        const response = await apiServiceVue.post(`${this.#contestsURL}giveaway/new`, {
            contestDTO: contestDTO,
            signedOnce: signedOnce,
            walletAddress: walletAddress
        });
        const body = response.data;
        const savedContestDTO = body.contestDTO;

        return new GiveawayContestSettingsDTO(savedContestDTO);
    }

    /**
     * @param {Number} id
     * @returns {Promise<GiveawayContestSettingsDTO>}
     */
    async getGiveawayContest(id) {
        const response = await apiServiceVue
            .get(process.env.VUE_APP_CONTESTS_URL + 'giveaway/' + id);
        return response.data.giveaway;
    }


    /**
     *
     * @param {Number} id
     * @param {ContestPublishStatuses} desiredStatus
     * @returns {Promise<*>}
     */
    async waitForGiveawayStatus(id, desiredStatus) {
        const maxWaitTime = 4 * 60 * 1000;   // wait for 4 min in total
        const waitTime = 2 * 1000;       // check every 2 seconds
        const waitLoops = maxWaitTime / waitTime;

        for (let i = 0; i < waitLoops; i++) {
            await (new Promise(resolve => {
                setTimeout(resolve, waitTime)
            }));

            const dto = await this.getGiveawayContest(id);
            const isInDesiredStatus = dto?.publish_status === desiredStatus;

            if (isInDesiredStatus) {
                return;
            }
        }
        throw new Error("Waiting for giveaway update exceeded time limit")
    }

    /**
     *
     * @param {Number} id
     * @param {String[]} winners
     * @returns {Promise<void>}
     */
    async waitForGiveawayWinnersUpdate(id, winners) {
        const maxWaitTime = 4 * 60 * 1000;   // wait for 4 min in total
        const waitTime = 2 * 1000;       // check every 2 seconds
        const waitLoops = maxWaitTime / waitTime;

        for (let i = 0; i < waitLoops; i++) {
            await (new Promise(resolve => {
                setTimeout(resolve, waitTime)
            }));

            const dtoWinners = (await this.getGiveawayContest(id))
                .winners
                .map(winner => winner.toLowerCase());

            const isInDesiredStatus = winners.every(winner => dtoWinners.includes(winner.toLowerCase()));

            if (isInDesiredStatus) {
                return;
            }
        }
        throw new Error("Waiting for giveaway update exceeded time limit")
    }
};
