var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-overlay",
        { attrs: { value: !_vm.isComponentInitialized } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-spacer", { staticClass: "hidden-md-and-down" }),
          _c(
            "v-col",
            { staticClass: "pt-0 px-8 mb-8 rounded-b-xl background" },
            [
              _c("h1", { staticClass: "text-center" }, [
                _vm._v(" Giveaway Manager "),
              ]),
            ]
          ),
          _c("v-spacer", { staticClass: "hidden-md-and-down" }),
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { color: "background" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _vm._l(
                    Object.entries(_vm.filters.date),
                    function ([name, properties]) {
                      return _c(
                        "v-col",
                        { attrs: { cols: "6", md: "3" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "min-width": "auto",
                                "offset-y": "",
                                transition: "scale-transition",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: _vm.ld.startCase(name),
                                                  clearable: "",
                                                  color: "secondary",
                                                  outlined: "",
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                },
                                                on: {
                                                  "click:clear": function (
                                                    $event
                                                  ) {
                                                    properties.value = []
                                                  },
                                                },
                                                model: {
                                                  value: (
                                                    properties.value || []
                                                  ).join(" - "),
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      properties.value || [],
                                                      "join(' - ')",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "(properties.value || []).join(' - ')",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: properties.popup,
                                callback: function ($$v) {
                                  _vm.$set(properties, "popup", $$v)
                                },
                                expression: "properties.popup",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  color: "secondary",
                                  "first-day-of-week": "1",
                                  "no-title": "",
                                  range: "",
                                  "show-current": "",
                                },
                                on: {
                                  input: function ($event) {
                                    properties.popup =
                                      properties.value.length < 2
                                  },
                                },
                                model: {
                                  value: properties.value,
                                  callback: function ($$v) {
                                    _vm.$set(properties, "value", $$v)
                                  },
                                  expression: "properties.value",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "10", md: "5" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.filters.autocomplete.tags.options,
                          "auto-select-first": "",
                          clearable: "",
                          color: "secondary",
                          "deletable-chips": "",
                          "full-width": "",
                          "hide-selected": "",
                          "item-color": "secondary",
                          label: "Tags",
                          multiple: "",
                          outlined: "",
                          "small-chips": "",
                        },
                        model: {
                          value: _vm.filters.autocomplete.tags.selected,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.filters.autocomplete.tags,
                              "selected",
                              $$v
                            )
                          },
                          expression: "filters.autocomplete.tags.selected",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2", md: "1" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "py-7 w-100",
                          attrs: {
                            title: "Search for contests.",
                            loading: _vm.entities.contests.loading,
                            small: "",
                          },
                          on: { click: _vm.fetchContests },
                        },
                        [
                          _c("v-icon", [
                            _vm._v(" mdi-feature-search-outline "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm.services.giveawayContestRewardContract
                ? _c("contest-contract-wizards", {
                    attrs: {
                      title: "Contract Address (Wizards, Enhancements)",
                      services: _vm.services,
                    },
                  })
                : _vm._e(),
              _vm.servicesV2.giveawayContestRewardContract
                ? _c("contest-contract-lands", {
                    attrs: {
                      title: "Contract AddressV2 (Lands)",
                      services: _vm.servicesV2,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "lighten-1",
          attrs: { color: "background", "min-height": "500px" },
        },
        [
          _c(
            "v-overlay",
            {
              attrs: {
                value:
                  _vm.isComponentInitialized && _vm.entities.contests.loading,
                absolute: "",
              },
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              !_vm.entities.contests.loading
                ? _c("v-data-iterator", {
                    attrs: { items: _vm.entities.contests.DTOs },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _c(
                                "v-row",
                                _vm._l(props.items, function (item) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: item.id + "_" + item.name,
                                      attrs: {
                                        cols: "12",
                                        lg: "3",
                                        md: "4",
                                        sm: "6",
                                      },
                                    },
                                    [
                                      _c("contest-administration-card", {
                                        attrs: {
                                          "all-tags":
                                            _vm.filters.autocomplete.tags
                                              .options,
                                          item: item,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2493960494
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }