import {ContestPublishStatuses, ContestRewardTypes} from "@/classes/contests/ContestHelperClasses";

export default class GiveawayContestSettingsDTO {

    /** @type {number} */
    id = undefined;
    /** @type {string} */
    name = 'New Contest';
    /** @type {number} */
    start_timestamp = undefined
    /** @type {number} */
    end_timestamp = undefined
    /** @type {string} */
    icon_name = "icon-contest";
    /** @type {string} */
    background_name = "contest-bg";
    /** @type {string[]} */
    tags = [];
    /** @type {string[]} */
    winners = [];
    /** @type {ContestRewardTypes | string} */
    reward_type = ContestRewardTypes.WIZARD;
    /** @type {ContestReward[]} */
    rewards = [{amount: 0}];
    /** @type {ContestWinnerStatus[]} */
    winner_statuses = undefined;
    /** @type {ContestPublishStatuses | string} */
    publish_status = ContestPublishStatuses.NEW


    /**
     *
     * @param {Object} contestSettings
     */
    constructor(contestSettings) {
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);
        this.start_timestamp = today.getTime();

        const nextMonth = new Date();
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        nextMonth.setUTCHours(0, 0, 0, 0);
        this.end_timestamp = nextMonth.getTime();

        contestSettings && Object.assign(this, contestSettings)
    }
}
