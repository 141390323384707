var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", lg: "5", md: "5", sm: "12" } },
        [
          _c("v-text-field", {
            attrs: {
              value: _vm.services.contestAddress,
              color: "golden",
              label: _vm.title,
              outlined: "",
              readonly: "",
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "v-chip",
                      {
                        staticClass: "ml-2",
                        attrs: {
                          color: "#435561",
                          small: "",
                          label: "",
                          target: "_blank",
                          href: _vm.bscLink,
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: "/img/icons/crypto/bscscan.ico" },
                        }),
                      ]
                    ),
                    _c("copy-button", {
                      attrs: { text: _vm.services.contestAddress },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("v-col", { attrs: { cols: "5", lg: "3", md: "3", sm: "5" } }),
      _c(
        "v-col",
        { attrs: { cols: "5", lg: "3", md: "3", sm: "5" } },
        [
          _c("v-text-field", {
            attrs: {
              loading: _vm.entities.wizards.onWallet.loading,
              value: _vm.entities.wizards.onWallet.list.length,
              color: "golden",
              label: "Lands On Contract",
              outlined: "",
              readonly: "",
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          loading: _vm.entities.wizards.onWallet.loading,
                          color: "#435561",
                          small: "",
                          title: "Refresh amount.",
                        },
                        on: { click: _vm.getWalletLands },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-refresh"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("v-col", { attrs: { cols: "2", md: "1" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }